.post-list {
    &__post-item {
        &__title {
            padding-top: 45px;
            padding-bottom: 45px;
            .mbr-section-title {
                font-size: 1.6rem;
                font-weight: 700;
            }
        }
        &__content-item {
            padding-top: 45px;
            .mbr-section-title {
                font-size: 1.125rem;
                width: 100%;
            }
            .mbr-text {
                font-size: .9rem;
                font-weight: 400;
            }
            .buttons-wrap {
                text-align: center;
                button {
                    padding: 10px 15px;
                    font-weight: 600;
                }
            }
        }
    }
}