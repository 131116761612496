.login {
    &__submit-button:disabled {
        background-color: #0654f8 !important;
        border-color: #0654f8 !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &__links {
        &__item {
            font-size: 1rem;
        }
    }
}