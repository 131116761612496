#footer {
    .empty-container {
        display: block;
        padding: 20px;
        height: 60px;
        width: 100%;
    }

    .container {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f5f5f5;
        z-index: 100;
        text-align: center;

        a {
            color: $dark-gray;
            padding: 0px 20px;
            font-weight: 500;
        }
        @media only screen and (max-width: 576px) {
            padding-right: 0;
            padding-left: 0;
            a {
                padding: 0 5px;
            }
        }
    }
}

#footer-logged-out {
    border-top: 1px solid #eeeeee;
    .container {
        font-size: 14px;
        padding: 60px 50px;
        max-width: 1024px;
        margin: 0 auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row;
        flex-flow: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}