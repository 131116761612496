.prescription-form {
    .form {
        .sub-title {
            flex-basis: 66%;
        }
        .custom-control-label {
            padding-top: 0.25rem;
            padding-left: 0.5rem;
            &::before, &::after {
                width: 1.5rem;
                height: 1.5rem;
            } 
        }
    }
    .mb-6-5{
        margin-bottom: 6.5rem;
    }
}

.payment-back-btn-container {
    float: right;
    width:60%;
    text-align: center;
    padding:20px 0px;

    button{
        width:100%;
    }
}
.cost-description{
    padding:20px 0;
}