#icd-select{
    position: relative;

    input {
        width: 90%;
    }

    ul {
        padding: 0px;
        margin: 0px;
        width: 95%;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid darkgray;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
        position: absolute;
        z-index: 1;
    
        li:hover {
            background: $highlight-gray;
        }
    
        li {
            // width: 100%;
            list-style-type: none;
            padding: 5px 10px;
            border: 0.001em solid #c0c0c0;
            background: $smoke-white;
            cursor: pointer; 
        }
    }
    
    .list {        
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .form-control {
        border: none;
        background-color: inherit;
        &:focus {
            box-shadow: none;
        }
    }

    .search-input {
        border: 1px solid #e8e8e8;
        // background-color: #f5f5f5;
        border-radius: 10px;
    }
}
