#api-not-available {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    opacity: 0.7;
    z-index: 10001;

    .text {
        color: red;
        font-size: 48px;
        padding: 20px;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        .text {
            font-size: 40px;            
        }
    }

    @media only screen and (max-width: 576px) {
        .text {
            font-size: 32px;            
        }
    }
}