.loading {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    opacity: 0.7;
    z-index: 10000;

    .text {
        position:absolute;
        top:58%;
        color: rgb(54, 215, 183);
        font-size: 18px;
        // padding-left: 20px
    }
}

/*
white overlay
*/

.loading-whiteoverlay {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    // opacity: 0.7;
    z-index: 10000;

    .text {
        position:absolute;
        top:58%;
        color: rgb(54, 215, 183);
        font-size: 18px;
        // padding-left: 20px
    }
}