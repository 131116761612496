.patient-page {
    h1 {
        display: flex;
        align-items: center;
    }

    .patient-name {
        font-size: 24px;
        color: gray;
    }

    .content {
        display: flex;

        > .left-panel {
            flex-basis: 65%;

            .patient-info {
                // display: flex;
                width: 100%;
        
                .patient-name {
                    font-weight: 500;
                    font-size: 14px;
                }
        
                button {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }

                hr {
                    margin-top:5px;
                    margin-bottom:15px;
                }
            }

            .title {
                font-weight: 600;
                font-size: 20px;
                color: $title-blue;

                .btn {
                    padding: 2px 6px;
                    font-size: 14px;
                }
            }
        }
    
        > .right-panel {
            display: flex;
            flex-direction: column;
            flex-basis: 35%;
            padding-left: 10px;
    
            .status {
                font-size: 24px;
                margin-bottom: 20px;
        
                .badge {
                    padding: 8px 16px;
                    font-weight: 500;
                }
            }
        }      
    }

    strong {
        color: $title-gray;
        font-size: 14px;
    }
}