#nav-bar-dashboard{
    .navbar {
        display: flex;
        max-width: 1024px;
        margin: 0 auto;
        // margin-bottom:150px;
        .navbar-brand {
            position: fixed;
            top: 14px;
            padding: .5rem 0;
            font-size: 1.10rem;
            span {
                color: #212529;
                font-weight: 800;
                margin-left: 10px;
                // background-color: red; // for test
            }
        }
        // #navbarContent {
        //     .navbar-nav {
        //         background: white;
        //     }
        //     .d-flex {
        //         background: white;
        //     }
        // }

        .btn-nav {
            font-weight: bold;
            font-size: 16px;
            color: #444;
            text-decoration: none;
            width: max-content;
            :hover {
                border-bottom: 2px solid rgba(68, 127, 255, 0.1);
            }
            span {
                padding: 5px 1px;
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            }
        }

        // .btn-nav {
        //     font-weight: bold;
        //     font-size: 16px;
        //     color: #444;
        //     text-decoration: none;
        //     :hover {
        //         border-bottom: 2px solid rgba(68, 127, 255, 0.1);
        //     }
        //     span {
        //         padding: 5px 1px;
        //         border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        //     }
        // }

        .form-control {
            padding: .75rem 1rem;
            border-width: 0;
            border-radius: 0;
        }

        .user-data { 
            color: #808080;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 8px;

            div {
                cursor: pointer;
            }
        }

        .user-data:hover {
            text-decoration: none;
        }

        .message-icon {
            background: #DFDFDF;
            margin-left: auto!important;
            margin-right: 20px!important;
        }
        
        .version {
            padding: 20px;
            color: $theme-dark;
        }
    }
    .navbar-fixed-top {
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    .logged-in {
        max-width: 100%;
        margin: 0 0 0 0;
    }
    /*
    * Sidebar
    */      
    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto;       /* Scrollable contents if viewport is shorter than content. */
    }
    
    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
        }
    }
    
    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100; /* Behind the navbar */
        padding: 80px 0px; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

        .nav-link {
            font-weight: 500;
            color: $theme-dark;
            display: flex;
            align-items: center;
            padding: 10px 16px;
        }

        .nav-link.active {
            color: $blue;
        }

        .message-count {
            padding-left: 10px;

            span {
                padding: 3px 9px;
                font-size: 12px;
                background-color: lightgray;
            }
        }
    }

    .collapse-menu {
        .nav-link {
            font-size: 16px;
            color: $black;
            display: flex;
        }
    }
    
    .sidebar-heading {
        font-size: 20px;
        font-weight: 500;
    }
    
    .link-disabled {
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
    }
    
    .menu-text {
        padding-left: 10px;
        font-size: 14px;
    }
    
    .menu-icon {
        text-align: center;
        width: 25px;
    }
}

@media only screen and (max-width: 768px) {
    #nav-bar-dashboard {
        .navbar {
            .btn-nav {
                margin: 0 !important
            }
            .message-icon {
                display: none;
                margin-left: unset!important;
                margin-right: unset!important;
            }
            .navbar-brand {
                position: unset;
                top: unset;
            }
        }
    }
}

