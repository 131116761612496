#edit-medical-info-page{
    h1 {
        display: flex;
        align-items: center;

        .person {
            color: gray;
            font-size: 26px;
        }
    }
}