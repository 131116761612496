.badge-item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    max-width: 300px;
    white-space: normal;
    font-weight: 400;
    text-align: left;
    border-radius: 5px;
    background-color: $badge-gray;
    margin: 2px;
    cursor: pointer;

    .close {
        padding-left: 10px;
        padding-bottom: 5px;
    }
}