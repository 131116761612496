#drug-search, .drug-search-dumb {
    position: relative;

    > input {
        margin-bottom: 20px;
    }
    
    .search-list {
        margin-top: -20px;

        ul {
            position: absolute;
            width: 100%;
            z-index: 1;
            padding: 0px;
            max-height: 320px;
            overflow-y: auto;
            border: 1px solid darkgray;
            border-top: none;
            border-radius: 0px 0px 5px 5px;
        
            li:hover {
                background: $highlight-gray;
            }
        
            li {
                width: 100%;
                list-style-type: none;
                padding: 0.75em 1em;
                border: 0.001em solid #c0c0c0;
                background: $smoke-white;
                cursor: pointer;
                
                .medication {
                    font-weight: bold;
                    display: block;
                }
            
                .administration {
                    display: block;
                }
                
                .ingredients {
                    font-size: 0.8em;
                }
            }
        
            .cross-out {
                color: red;
                text-decoration: line-through;
                cursor: not-allowed;
            }
        }
    }    
        
    .multiple-drugs {
        .select-drug {
            font-size: 1.25em;
            font-weight: 500;
        }

        > div {
            margin-bottom: 15px;
        }

        ul {
            padding: 0px;
            width: 500px;
        
            li {
                padding: 5px 10px;
                margin: 10px;
                border-radius: .25rem;
                list-style-type: none;
                border: 0.001em solid #c0c0c0;
                background: $smoke-white;
                cursor: pointer;

                .ingredients-medication {
                    display: block;
                }
            }
        }
    }

    &__title {
        font-size: 12px;
        color: #17a2b8;
        font-weight: 600;
        padding: 4px 0;
    }

    &__input {
        height: 30px;
        padding: 4px 8px;
        font-size: 12px;
    }

    &__drug-details {
        font-size: 12px;
    }
}
