#pending-labtest {
    width: 100%;
    max-height: 500px;
    // overflow-y: auto;
    overflow-y: scroll;
    
    .labtest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
        border: 1px solid #A9A9A9;
        border-radius: 5px;
        background-color: #F2F2F2;

        
        &--selected {
            border: dashed 5px $selected-blue;
        }
    }

    
}