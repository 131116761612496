#agreement-dialog {
    .text-info {
        font-size: 16px;
        padding-left: 10px;
    }

    .content {
        width: 100%;
        height: 500px;
        

        .title {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
        }

        .fee-box {
            font-size: 17px;
        }
    
        .section {
            > .title {
                text-align: left;
                font-size: 16px;
            }
    
            > div {
                margin-bottom: 15px;
            }
    
            .sub-section {
                padding: 0px 40px;
            }
        }
    }

    .action {
        display: flex;
        justify-content: space-between;

        .agreement {
            padding-left: 40px;
        }

        .agreement.disabled {
            cursor: not-allowed;

            input, label {
                cursor: not-allowed;
            }
        }
    }
}