.note-reply {
    background-color: $white;
    border-radius: 0px 0px 10px 10px;
    font-size: 12px;

    .title {
        padding: 2px 10px;
        font-weight: 500;
        color: gray;
        border-bottom: 1px solid darkgray;
        font-size: 13px;
    }

    .reply {
        padding: 5px 10px;
        padding-bottom: 5px;
        color: $pink-red;
        
        .from {
            display: flex;
            justify-content: space-between;

            .person {
                font-weight: 500;
            }

            .replied-at {
                color: gray;
                font-size: 11px;
            }
        }

        .message {
            padding: 0px;
            display: flex;
            justify-content: space-between;
        }
    }
}
