#custom-image {
    img {
        width: 150px;
    }

    .fa.fa-edit {
        position: absolute;
        left: 148px;
    }

    .pointer {
        cursor: pointer;
    }
}

#image-dialog {
    img {
        width: 1000px;
    
        @media only screen and (max-width: 1200px) {
            width: 900px;
        }
    
        @media only screen and (max-width: 992px) {
            width: 750px;
        }
    
        @media only screen and (max-width: 768px) {
            width: 600px;
        }
    }

    .zoom-in-container {
        width: 50%;
    }

    .buttons {
        text-align: right;
    }
}