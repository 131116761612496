#other-surgery {
    input {
        display: inline-block;
        margin-right: 5px;
        width: 80%;
    }

    button {
        vertical-align: initial;
    }

    .list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
}