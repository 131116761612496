#edit-sleep-info-page {
    .custom-control-label {
        cursor: pointer;
    }

    .form-control {
        width: auto;
        display: inline-block;
    }

    input.form-control {
        width: 80px;
    }

    .custom-range {
        width: 300px;
        height: auto;
    }

    // react-datepicker
    .react-datepicker-wrapper input {
        width: 200px;
    }

    .react-datepicker__time-container {
        width: auto;
    
        .react-datepicker__time .react-datepicker__time-box {
            width: auto;
        }
    }
}