:root {
    --jumbotron-padding-y: 3rem;
}
  
.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: $white;
    // img {
    //     max-height: 325px;
    // }
}
@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(var(--jumbotron-padding-y) * 2);
        padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }

    .jumbotron p:last-child {
        margin-top: 30px;
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

// .jumbotron .container {
//     max-width: 40rem;
// }

.btn-home {
    padding: 0.35em 0.75em;
    margin: 10px;
}