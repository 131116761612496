
// .footer-container{
    .footer1 {
        border:none;
        padding-top: 75px;
        padding-bottom: 75px;
        background-color: #25303e;
        &__col {
            margin-bottom: 32px;
        }
        .container {
           
                .list {
                    .list-item {
                        .content {
                            h4 {
                                a {
                                    font-size: 13px;
                                    color: #4f87fb;
                                    &:hover {
                                        text-decoration: none;
                                    }
                                } 
                            }
                        }
                    }
                }
                .line{
                    width: 50px;
                    height: 2px;
                    display: inline-block;
                    background: #4f87fb;
                    transition: background .3s;
                    margin-bottom: 2rem;
                }
    
                .media-container-row{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    align-items: start;
    
                    @media (min-width: 992px) {
                        flex-wrap: nowrap;
                    }
                    
    
                }
    
                .widget-title{
                    font-weight: 600;
    
                    .display-7 {
                        font-family: Muli,sans-serif;
                        font-size: 1.125rem;
                        line-height: 27px;
                        letter-spacing: 0.5px;
                        font-weight: 600;
                    }
                }
    
    
    
                .align-left {
                    text-align: left; 
                }
    
                
                .item {
                    display: flex;
                    align-items: center;
                    padding-bottom: .5rem;
                    margin-bottom: .6rem;
    
                    .card-img{
                        width: auto;
                        background-color: #4f87fb;
                        border-radius: 100%;
                        
                        .img1{
                            display: block;
                            padding: 6px;
                            font-size: 13px;
                            color: #fff;
                            background-color: #4f87fb;
                            border-radius: 50%;
                            line-height: 1.3;
                            .fa-map-marker-alt {
                                font-size: 13px;
                                text-align: center;
                                width: 13px;
                            }
                        }
                    }
    
                    .card-box{
                        .item-title{
                            font-size: 16px;
                            line-height: 1.5;
                            font-weight: 300;
                        }
                    }
                    h4{
                        padding-left: 10px;
                        margin: 0;
                    }
    
                }
                .link {
                    display: flex;
                    // align-items: center;
                    text-align: left;
                    .card-img {
                        width: auto;
                    }
                    .card-box {
                        .link-title {
                            padding-left: 10px;
                            padding-bottom: .5rem;
                            margin-bottom: .6rem;
                            a {
                                color: #4f87fb;
                                &:hover {
                                    text-decoration: none;
                                    color: #4f87fb;;
                                }
                            }
                        }
                    }
                }
    
                .display-4{
                    font-size: 16px;
                    line-height: 1.5;
                    font-weight: 300;
                }
    
                .img2{
                    color: #fff;
                    font-size: 10px;
                }
            
        }

        .tips{
            height: 70px;
            width: 70px;
            margin: 4px;
            object-fit: cover;
        }

        .box-list{
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            font-weight: 300;
        }
        .box-list.display-4 {
            line-height: 24px;
        }

        .box-list li {
                padding: 10px 0;
                color: #fff;
        }
        .box-list li:first-child {
                padding-top: 0;
        }
        .box-list li:last-child {
            border-bottom: none;
        }


    }
    .footer2 {
        background:#25303E;
        border:none;
        padding-top: 30px;
        padding-bottom: 30px;
        .links{
            text-align: center;
            color: #fff;
        }

        p {
            margin: 0;
        }
        .display-4, .display-4>.mbr-iconfont {
            font-size: .9rem;
        }

        a {
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            color: #fff;
            background-color: initial;
        }
        a:hover{
            color: #4f87fb!important;
            transition: all .3s;
        }
        
    }
 
// }