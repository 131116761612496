
.person-icon {
    display: inline-block;

    // div {
    //     div {
    //          background-color: $pale-gray;
    //     }
    // }
}

// .person-icon.light-theme {
//     div {
//         // border-color: $light-gray;
//         // color: $light-gray;
        
//         // div {
//         //     background-color: $gray;
//         // }
//     }
// }