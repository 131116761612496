#pharmacy-select {
    position: relative;

    ul {
        padding: 0px;
        margin: 0px;
        margin-bottom: 50px;
        max-height: 100px;
        width: 100%;
        overflow-y: auto;
        border: 1px solid darkgray;
        border-top: none;
        border-radius: 0px 0px 5px 5px;
        position: absolute;
        z-index: 1;
    
        li:hover {
            background: $highlight-gray;
        }
    
        li {
            width: 100%;
            list-style-type: none;
            padding: 5px 10px;
            border: 0.001em solid #c0c0c0;
            background: $smoke-white;
            cursor: pointer; 
        }
    }
    
    .item {
        .badge {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            max-width: 700px;
            white-space: normal;
            font-weight: 400;
            text-align: left;
            border-radius: 5px;
            background-color: $badge-gray;
            margin: 2px;
            cursor: pointer;
        
            .close {
                padding-left: 10px;
                padding-bottom: 5px;
            }
            button {
                width: 145px;
                margin-left: 10px;
              }
        }
    }
}
