#invoice-dialog {
    margin: auto;
    width: 650px;
    margin-top: 10px;
    border: 1px solid black;
    
    .header {
        border-bottom: 1px solid black;
        align-items: center;

        .banner {
            display: flex;
            
            .logo {
                padding: 20px 40px;
                font-size: 40px;
            }
    
            .clinic {
                border-left: 1px solid black;
                padding: 5px 10px;
    
                .clinic-owner {
                    font-size: 14px;
                    font-weight: bold;
                }
    
                .clinic-name {
                    font-size: 20px;
                    font-weight: bold;
    
                    span {
                        font-style: italic;
                    }
                }
    
                .clinic-address {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .content {
        .title {
            padding: 10px;            
            text-align: center;

            .text {
                font-size: 26px;
            }

            .prescriptionId {
                font-size: 16px;
            }
        }

        .person {
            padding: 5px 10px;

            .name {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .medications {
            border-bottom: 1px solid black;

            .medication {
                border-top: 1px solid black;
                margin-bottom: 0px;
        
                .badge-medication {
                    padding: 10px;            
                }
            }
        }

        .price {
            padding: 10px;
            width: 60%;

            .title {
                padding: 5px 0px;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
            }

            .charge, .refund {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}