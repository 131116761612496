#sleep-assessment-form {
    margin: auto;
    width: 650px;
    margin-top: 10px;
    border: 1px solid black;

    .header {
        padding: 10px 20px;

        div {
            font-size: 20px;
            font-weight: 500;
        }
        &__comment {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .personal-info {
        padding: 10px;
        display: flex;
        border-top: 1px solid black;

        .field-name {
            display: inline-block;
            width: 120px;
            //font-weight: 500;
        }
    }
    
    .sleep-info {
        padding: 10px 20px;
        border-top: 1px solid black;

        strong {
            font-size: 14px;
            font-weight: 400;
            color: $black;
        }

        .col-md-12 {
            margin: 5px 0px !important;
        }
    }

    .footer {
        padding: 10px 20px;        
        border-top: 1px solid black;

        .sigCanvasSleepment {
            width: 250px;
            height: 60px;
            border: 1px solid black;
        }

        > div {
            display: flex;
            justify-content: space-between;

            > div {
                > div {
                    padding: 5px 0px;
    
                    .field-name {
                        display: inline-block;
                        padding-right: 10px;
                        //font-weight: 500;
                    }
                }
            } 
        }
    }

    .title {
        font-size: 18px;
        //font-weight: 500;
        margin-bottom: 10px;
    }
}