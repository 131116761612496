.prescription-page {
    .prescription-id {
        font-size: 24px;
        color: $title-gray;
    }

    .content {
        display: flex;

        > .left-panel {
            flex-basis: 65%;
            display: flex;
            flex-direction: column;
    
            .title {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 10px;
                color: $title-blue;
            }
            
            .medications {
                flex-basis: 60%;
                padding: 0px 10px;  
            }
    
            .patient-info {
                flex-basis: 40%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 10px;
        
                .patient-name {
                    font-weight: 500;
                    font-size: 14px;
                }
        
                button {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    
        > .right-panel {
            display: flex;
            flex-direction: column;
            flex-basis: 35%;
            padding-left: 10px;
    
            .status {
                font-size: 24px;
                margin-bottom: 20px;
        
                .badge {
                    padding: 8px 16px;
                    font-weight: 500;
                }

                .status-badge {
                    .extra-info {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}