#checkout-dialog {
    .title {
        padding: 12px 16px;
        background-color: $pale-gray;

        h2 {
            span {
                font-size: 18px;
                color: $dark-gray;
            }
        }
    }

    .content {
        padding: 24px;
        background-color: $light-gray;

        > div {
            margin: 0px;
        }
    }

    .action {
        margin: 0px;
        padding: 8px 4px;
        background-color: $smoke-white;
    }
}