.prescription-table {
    margin-top: 20px;

    table {
        min-width: 700px;

        tbody {
            tr {
                .name {
                    width: 30%;
                }

                .created-at {
                    width: 15%;
                }

                .status {
                    font-size: 16px;
            
                    .badge {
                        padding: 5px 10px;
                        font-weight: 500;
                    }
                }

                .invoice {
                    width: 10%;
                }
            }

            tr:hover {
                background-color: $light-green;
                box-shadow: inset 1px 0 0 #dadce0,
                            inset -1px 0 0 #dadce0,
                            0 1px 2px 0 rgba(60,64,67,.3),
                            0 1px 3px 1px rgba(60,64,67,.15);
                z-index: 1;
                cursor: pointer;
            }
        }
    }

    .font-weight-500 {
        font-weight: 500;
    }
}