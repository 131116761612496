.message-table {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;        
    }


    table {
        border-top: 1px solid lightgray;

        .unread {
            background-color: $white;
    
            .inbox,
            .subject {
                font-weight: bold;
            }
    
            .updated-at {
                color: black;
                font-weight: bold;
            }
        }

        tr {
            background-color: rgba(242,245,245,0.8);
            box-shadow: inset 0 -1px 0 0 rgba(100,121,143,0.122);
  
            td {
                cursor: pointer;
            }

            .name {
                width: 20%;
                font-size: 14px;
            }

            .replies-count {
                color: gray;
                font-size: 12px;
                margin-left: 5px;
            }
            
            .content {
                width: 70%;
                font-size: 14px;

                .message {
                    color: gray;
                }
            }

            .action {
                //display: none;
                 display:table-cell;
                cursor: default;
                width: 10%;

                button {
                    display: flex;
                    padding: 0px;
                }
            }

            .updated-at {
                display: table-cell;
                width: 10%;
                color: gray;
                font-size: 12px;
            }
        }
    }

    .tab-inbox {
        tr:hover {
            box-shadow: inset 1px 0 0 #dadce0,
                        inset -1px 0 0 #dadce0,
                        0 1px 2px 0 rgba(60,64,67,.3),
                        0 1px 3px 1px rgba(60,64,67,.15);
            z-index: 1;

            .action {
                display: table-cell;
            }
    
            .updated-at {
                //display: none;
                display:table-cell;
            }
        }
    }
}