.screenshot {
    width: 800px;

    @media only screen and (max-width: 1200px) {
        width: 600px;
    }

    @media only screen and (max-width: 992px) {
        width: 500px;
    }

    @media only screen and (max-width: 768px) {
        width: 400px;
    }
}