.status-badge {
    padding: 3px 0px;

    .extra-info {
        font-size: 12px;
        padding-left: 5px;
        
        span {
            font-weight: 500;
            padding: 0px 5px;
            color: $medium-gray;
        }
    }
}