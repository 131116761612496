#pending-sleep-assessment {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    
    .sleep-assessment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;

        .id {
            margin-left: 20px;
            color: $white;
        }
        
        &--selected {
            border: dashed 5px $selected-blue;
        }
    }

    
}