#last-login-dialog {
    .field-name {
        display: inline-block;
        width: 120px;
        color: $dark-gray;
        font-weight: 500;
    }

    h2 {
        display: flex;
        justify-content: space-between;

        .count-down {
            display: inline-block;
        }
    }
}