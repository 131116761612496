.medication {
    display: flex;
    margin-bottom: 10px;

    .badge-medication {
        display: flex;
        padding: 8px;
        border: 1px solid $border-gray;
        border-radius: .25rem;
        background-color: $badge-gray;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;  
        width: auto;
        box-sizing: border-box;
        list-style-type: none;
        background-repeat: no-repeat;
        background-size: 0.9em;
        background-position: 95% 50%;
        
        > .left-panel {
            .drug-title {
                display: flex;
                justify-content: space-between;
    
                .drug-name {
                    font-size: 15px;
                    font-weight: 700;
                }
            
                .drug-description {
                    font-size: 12px;
                    margin-left: 4px;
                }
    
                .edit {
                    margin-left: 20px;
                }
            }

            .status-badge {
                font-size: 15px;
                margin-left: 10px;
                padding: 0px;
        
                .badge {
                    padding: 5px 10px;
                    font-weight: 500;
                }              
            }
    
            .ingredients {
                font-size: 14px;
                display: block;
            }
    
            #drug-usage {
                font-size: 12px;
    
                select, input {
                    height: 30px;
                    padding: 4px 8px;
                    font-size: 12px;
                };
    
                .title, label {
                    padding: 4px 0px !important;
                    font-size: 12px;
                }
    
                .custom-checkbox {
                    margin-top: 8px !important;
                }
            }
        }        

        > .right-panel {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            padding-left: 10px;

            .remove {
                cursor: pointer;
                padding-left: 10px;
            }

            .action {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .fa-undo-alt {
                    font-size: 14px;
                    padding-top: 3px;
                }
            }
        }
    }

    .print.badge-medication {
        background-color: $white;
        border: none;
    }
}