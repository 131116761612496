#pending-prescription {
    overflow-y: auto;
    max-height: 500px;

    .prescription.selected {
        border: dashed 5px $selected-blue;
    }

    .prescription {
        display: flex;
        width: 100%;
        padding: 10px;
        margin: 5px 0px;
        border: 1px solid darkgray;
        border-radius: 5px;

        > .left-panel {
            flex-basis: 70%;
            
            .medication:last-child {
                margin: 0px;
            }
        }

        > .right-panel {
            flex-basis: 30%;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .created-at {
                color: $dark-gray;
                margin-bottom: 5px;
            }

            .prescription-id {
                color: $white;
            }
        }
    }
}