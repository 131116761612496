.note-panel {
    background-color: $badge-gray;
    border-radius: 5px;
    border: 1px solid $border-gray;

    .notes {
        overflow-y: auto;
        max-height: 600px;
    }

    textarea {
        font-size: 13px;
        padding: 5px;
    }
}