// gray
$dark-gray: #343a40;
$medium-gray: #606060;
$gray: gray;
$pale-gray: #dfdfdf;
$light-gray: #f2f2f2;

// purple
$pale-purple: #e6e6ff;

// blue
$info-blue: #17a2b8;
$blue: blue;
$pale-blue: #fefde5;
$light-blue: #bee5eb;

// white
$white: white;
$smoke-white: #f5f5f5;

// black
$black: black;

// green
$light-green: #e6fff5;

// red
$pink-red: #ff6666;
$orange-red: #fa755a;


// theme color
$theme-dark: $dark-gray;
$highlight-gray: $pale-gray;
$badge-gray: $light-gray;
$title-gray: $medium-gray;
$border-gray: #ced4da;
$footer-purple: $pale-purple;
$selected-blue: $info-blue;
$title-blue: $info-blue;