.note {
    padding: 0px;
    border-radius: 10px;
    border: 1px solid lightgray;
    background-color: $white;
    margin-bottom: 10px;
    margin: 5px;

    .header {
        padding: 3px 5px;
        color: $white;

        a {
            margin-right: 5px;
        }

        .note-id {
            font-size: 10px;
            opacity: 0.1;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {        
        font-size: 14px;
        padding: 0px 5px;
        margin-bottom: 5px;

        .multiline-text {
            font-size: 13px;
        }
    }

    .footer {
        padding: 3px 10px;
        background-color: $footer-purple;
        border-radius: 0px 0px 10px 10px;
        color: gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        .created-at {
            font-size: 11px;
            margin-right: 5px;
        }

        .revision {
            a {
                font-size: 13px;
            }
        }
    }            
}

.note.is-sent {
    background-color: $light-green;
}

.note.is-sent-to-emr {
    background-color: $orange-red;
}

.note.has-reply {
    .footer {
        border-radius: 0px;
    }
}

.note.selected {
    border: dashed 5px $selected-blue;
}