.text-container {
    display:flex;
    flex-direction: column;
    height:100%;
    margin:0 100px;

    h1 {
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        background:#85BEF6;
        text-align: center;
        color:white;
        min-height: 150px;
        margin-bottom:20px;
    }
    .text{
        padding:20px 75px;
        text-align:center;
        font-size:20px;
        min-height:250px;
        
    }
    .contact-container{
        padding:20px 20px;
        text-align: center;
        background: #F8FAFB;
        width:100%;
        min-height: 200px;
        h4{
            margin-bottom: 15px;
        }
    }
    @media only screen and (max-width: 465px) {
        margin:0;
        .text{
            padding:0;
        }
    }
    @media only screen and (max-width: 321px) {
        margin:0;
        .text{
            padding:0;
        }
    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    }
}

.about-us {
    padding-top: 60px;
    padding-bottom: 30px;
    &__contents {

        padding: 3rem;
        .contents {
            margin-bottom: 40px;
            padding-right: 50px;
        }
        .mbr-subtitle {
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 2px;
            color: #4f87fb;
            margin-bottom: 10px;
            font-weight: 800;
        }
        .mbr-section-title {
            margin-bottom: 10px;
            color: #130947;
            line-height: 48px;
            letter-spacing: -.5px;
            font-weight: 700;
            font-size: 1.6rem;
        }
        &__sub-text {
            font-size: 20px;
            line-height: 1.5;
            color: #130947;
            letter-spacing: -.5px;
            margin-top: 40px!important;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-top: 10px;
            color: #6d7a8c;
        }
    }
    &__photo {
        &__flag {
            position: absolute;
            z-index: 10;
            left: -22px;
            top: 50%;
            transform: translateY(-50%);

            .widget-icon {
                display: inline-block;
                border-radius: 100%;
                padding: 31px;
                background-color: #fff;
                font-size: 2.45rem;
                color: #4f87fb;
                margin-right: 15px;
                z-index: 10;
            }
        }
        &__outer {
            padding: 100px;
            left: -80px;
            border-radius: 100%;
            border: 3px dashed #e3e3e3;
            position: absolute;
            z-index: 7;
            top: 50%;
            transform: translateY(-50%);
        }
        &__inner {
            border-radius: 100%;
            border: 3px dashed #e3e3e3;
            position: absolute;
            z-index: 7;
            top: 50%;
            transform: translateY(-50%);
            padding: 60px;
            left: -50px;
        }
        &__pad {
            img {
                max-width: 100%;
                max-height: 800px;
                z-index: 9;
                position: relative;
            }
        }
    }
    &__icon {
        width: 39px;
    }
}