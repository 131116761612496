.prescription-cost {
    font-size: 36px;
    flex-basis: 33%;
    text-align: center;

    .badge {
        padding: 12px 24px;
        font-weight: 600;
        margin: 10px;
    }
}