.nav-bar-dashboard-logout {

    // background-position: 50% 50%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-color: #eee;
    // position: relative;
    nav {
        position: fixed;
        background: #fff;
        transition: none;
        min-height: 50px;
        flex-wrap: nowrap;
        z-index: 1030;
        left: 0;
        right: 0;
        top: 0;
        padding: 0;
        .navbar-brand {
            margin-left: 32px;
        }
        .navbar-caption-wrap {
            margin: .3rem 0;
            display: flex;
            align-items: center;
            word-break: break-word;
            min-width: 7rem;
        }

        .menu-bottom {
            margin: auto;
            width: 100%;
            display: flex;
            padding: 0 2rem;
            position: relative;
            align-items: center;
            // justify-content: flex-end;
            justify-content: space-between;
            
            ul {
                flex-wrap: wrap;
            }
        }
        @media (max-width: 576px) {
            .menu-bottom {
                flex-direction: column;
            }
        }
    }
    &__menu-logo {
        a {
            font-size: 3.4375rem;
            font-weight: 700;
            text-decoration: none;
            &:hover/*, &:focus*/ {
                color: #4f87fb!important;
            }
        }
    }
    @media (max-width: 576px) {
        &__menu-logo {
            display: none;
        }
    }

    .navbar-collapse {
        ul {
            text-align: center;
        }
    }
    @media (max-width: 991px) {
        .navbar-collapse {
            padding-bottom: 1rem;
        }
    }
    
    &__menu {
        width: auto;
        display: flex!important;
        justify-content: flex-end;

        ul {
            display: flex;
            flex-direction: row;
        }

        &__item {
            a {
                font-size: 1.125rem;
                margin: 0 1.3rem!important;
                font-weight: 400;
                padding: .5rem 0!important;
                color: rgb(35, 35, 35);
                &:hover {
                    color: #4f87fb!important; 
                }
            }
        }
    }
    &__link--active {
        color: #4f87fb !important;
        font-weight: bold !important;
    }

    .navbar-toggler {
        outline: none;
    }

    @media (min-width: 992px) {
        .navbar-toggleable-sm .navbar-collapse {
            display: flex !important;
            justify-content: flex-end;

            ul {
                display: flex;
                flex-direction: row;
            }          
        }
        .navbar-toggler {
            display: none;
        }
    }

    @media(max-width: 780px){
        .nav-hide{
            display:none;
        }
    }

    
}