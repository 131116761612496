.price {
    margin-top: 0;
    padding-top: 105px;
    padding-bottom: 90px;
    &__content {
        &__text {
            color: #6d7a8c;
            line-height: 1.5;
            font-size: 1.125rem;
        }
    }

}