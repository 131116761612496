.register {
    .form-control {
        box-shadow: none;
        color: #565656;
        line-height: 1.43;
        min-height: 3.5em;
        padding: 1rem;
        font-size: 1.125rem;
    }
    label {
        font-size: 1rem;
    }
    .submit-button {
        margin: .4rem 0;
        padding: 10px 15px;
        font-size: 1.125rem;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.18);
        font-weight: 600;
        &:hover {
            color: #4f87fb!important;
            background: #fff!important;
            border-color: #fff!important;
        }
    }
}