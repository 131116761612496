.StripeElement {
    margin-bottom: 20px;
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 1px 3px 0 $gray;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 $info-blue;
}

.StripeElement--invalid {
    border-color: $orange-red;
}