.note-draft {
    padding: 0px 5px 5px 5px;

    .btn:disabled {
        cursor: not-allowed;
    }

    .add-note, .close-note {
        cursor: pointer;
        font-size: 20px;
        padding: 3px;
        margin: 2px;
    }

    .add-note:hover, .close-note:hover {
        background-color: lightgrey;
        border-radius: 20px;
    }
}