.add-medication-filter {
    display: flex;
    margin: 0;
    &__add-form {
        flex: 1;
    }
    &__download {
        display: flex;
        align-items: flex-end;
    }
}