@media (min-width: 576px){
    .jumbotron {
        padding: 4rem 2rem;
    }
}

@media (min-width: 768px){
    .jumbotron {
padding-top: calc(var(--jumbotron-padding-y)*2);
padding-bottom: calc(var(--jumbotron-padding-y)*2);
    }
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
}
.cid-s9MPQnQRip {
    text-align: center;
    font-size: 1.125rem;
    .modal-header {
        border-bottom: none;
        .modal-title {
            width: 100%;
            font-size: calc(1.30625rem + 0.56875*(100vw - 20rem)/28);
        }
    }
    .card-img {
        img {
            width: 100%;
        }
    }
}

.home-container {
    .stats-info {
        background: #fafafa;
        color: #606060;
        padding: 13px 150px 13px 150px;
        min-height: 200px;
        .accolade {
            padding: 15px;
            .icon-header {
                text-align: center;
            }
            .accolade-header {
                font-weight: bold;
                margin-top: 10px;
            }
            i {
                font-size: 3em;
                color: #447fff;
            }
            p {
                font-weight: 400;
            }
        }
    }
    .cid-s8e6SevhPb {
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: rgb(255, 255, 255);

        .contents {
            text-align: center;
            margin-bottom: 54px;

            .mbr-subtitle{
                color: #4f87fb;
                margin-bottom: 10px;
                font-weight: 800;
                font-size: 13px;
                letter-spacing: 2px;     
                line-height: 1.2;
                font-family: Muli,sans-serif;          
            }
            .mbr-section-title{
                margin-bottom: 20px;
                line-height: 48px;
                letter-spacing: -.5px;
                font-weight: 700;
                color: #130947;
                font-size: 1.6rem;
                font-family: Muli,sans-serif;
            }
            .mbr-section-text{
            line-height: 28px;
            margin-top: 20px;
            color: #6d7a8c;
            letter-spacing: 0;
            font-size: 1.125rem;
            font-family: Muli,sans-serif;
            font-weight: 300;
            }
        }

        .btn[class*=-outline] {
            border: none;
            padding: 0;
            margin: 0!important;
            box-shadow: none!important;
            &:hover {
                color: #4f87fb!important;
            }
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
        }

        .justify-content-center {
            justify-content: center!important;
        }

        .p-3 {
            padding: 1rem!important;
        }

        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border-radius: .25rem;
            border-style: none;
        }

        .card-wrapper {
            height: 100%;
            flex: 1 1;
            .card-img {
                position: relative;
                text-align: center;
                flex-shrink: 0;
                border-bottom-right-radius: calc(.25rem - 1px);
                border-bottom-left-radius: calc(.25rem - 1px);
                border-top-left-radius: calc(.25rem - 1px);
                border-top-right-radius: calc(.25rem - 1px);
                width: 100%;
                img {
                    transition: all .3s;
                    border-radius: 8px;
                    vertical-align: middle;
                    border-style: none;
                    width: 100%;
                }
                .meta-category{
                    background-color: #4f87fb;
                    display: inline-block;
                    padding: 6px 25px;
                    color: #fff;
                    position: absolute;
                    bottom: -15px;
                    right: 35px;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: .5px;
                    z-index: 9;
                    border-radius: 15px;
                }
            }

            .mbr-link-btn{
                .btn{
                    font-weight: 600!important;
                    line-height: 24px;
                    letter-spacing: 1px;
                    font-size: 12px;
                }
                .btn-white-outline{
                    background: none;
                    color:  #fff;
                }
                .btn-md {
                    border-radius: 6px;
                }
            }

            .card-box{
                padding-top: 2rem;
                .card-title{
                    color: #28303f;
                    transition: all .3s;
                    font-weight: 700;
                    margin-bottom: 10px; 
                    font-size: 22px;
                    line-height: 28px;
                    letter-spacing: -.5px;
                    font-family: Muli,sans-serif; 
                    &:hover {
                        color: #4f87fb;
                        transition: all .3s;
                        text-decoration: none;
                    }
                }
                .mbr-text{
                    margin-bottom: 25px;
                    color: #6d7a8c;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }

            @media (max-width: 768px){
                .card-box { 
                    text-align: center;
                }
            }
       
            
        }
        .btn[class*=-outline] {
            border: none;
            padding: 0;
            margin: 0!important;
            box-shadow: none!important;
        }
        .align-left {
            text-align: left;
        }
    }

    .cid-s8mZZpGuuw {
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: #f3f3f3;

        .main-title{
            font-size: 1.6rem;
        }
        .mbr-bold {
            font-weight: 700;
        }
        .align-center {
            text-align: center;
        }
        .mbr-black {
            color: #000;
        }
        .main-subtitle {
            color: #999;
            font-size: 1.125rem;
            font-family: Muli,sans-serif;
        }
        .mbr-regular {
            font-weight: 400;
        }
        
        .card {
            margin-bottom: 2rem;
            overflow: visible;
            background-color: initial;
            border: none;
            display: -webkit-flex;
        }
        .card-wrapper {
            background-color: #fff;
            padding: 1rem;
            transition: all .3s;
            flex: 1 1;
            &:hover {
                box-shadow: 0 30px 30px -20px rgba(50,50,50,.3)
            }
        }
        .card-box {
            text-align: center;
            border: 1px solid transparent;
            padding: 2rem 1rem;
            transition: all .3s;
            a {
                &:hover {
                    text-decoration: none;
                }
            }
            .card-text {
                color: #999;
            }
        }
        @media (max-width: 767px) {
            .card-box {
                border: 1px solid #f3f3f3;
            }
        }

    }

    .cid-s8HgOFvBHp {
        padding-top: 90px;
        padding-bottom: 90px;
        background-color: #fff;
        
        .container {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
        .align-center {
            text-align: center;
        }
        .contents{
            margin-bottom:70px;

            .mbr-subtitle {
                letter-spacing: 2px;
                line-height: 24px;
                font-size: 13px;
                color: #4f87fb;
                margin-bottom: 10px;
                font-weight: 800;
                font-family: Muli,sans-serif;
            }
            .mbr-section-title {
                margin-bottom: 20px;
                color: #130947;
                font-weight: 700;
                text-align: center;
                font-size: 1.6rem;
            }
            .mbr-section-text {
                line-height: 28px;
                letter-spacing: 0;
                margin-top: 20px;
                color: #6d7a8c;
                font-weight: 400;
                font-size: 1.125rem;
                
                }
            }

            .card {
                 margin-bottom: 2rem;
                 display: flex;
                 flex-direction: column;
                 min-width: 0;
                 word-wrap: break-word;
                 background-color: #fff;
                 background-clip: initial;
                 border: none; // 1px solid rgba(0,0,0,.125);
                 border-radius: .25rem;
                 .card-block {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 400;
                    letter-spacing: .03em;
                    position: relative;
                    background: #4f87fb;
                    color: #232323;
                    box-shadow: 0 1px 6px 0 rgba(0,0,0,.08);
                    padding: 1.9rem;
                    margin-bottom: 35px;
                    border-radius: 4px;
                    p {
                        color: #fff;
                        text-align: center;
                        margin-top: 0;
                        margin-bottom: 1rem;
                        a {
                            &:hover {
                                text-decoration: none;
                                color: #b3b3b3!important;
                            }
                        }
                    }
                    :before {
                        content: "";
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        margin-left: -14px;
                        width: 0;
                        height: 0;
                        border-left: 15px solid transparent;
                        border-top: 15px solid #4f87fb;
                        border-right: 15px solid transparent;
                    }
                 }
                 .card-footer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-top: none;
                    background: none;
                    padding: 0 1.25rem 1.25rem;
                    :last-child {
                        border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
                    }
                    .name-wrap {
                        .mbr-text {
                            a {
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                 }
            }

        }
    .cid-rSbev8cAt7 {
        padding-top: 0;
        padding-bottom: 0;
        background-color:  #4f87fb;

        .row {
            position: relative;
            box-sizing: border-box;
            padding-top: 100px;
            padding-bottom: 90px;
            transition: opacity 0.5s ease 0s;
            overflow: hidden;
            background-color: rgb(79, 135, 251) !important;
            // background-image: url(file:///C:/Users/adnan.butt/AppData/Local/Mobirise.com/Mobirise/projects/project-2020-08-09_192142/assets/images/08.jpg) !important;
            background-position: 50% center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            display: -webkit-flex;
            flex-wrap: wrap;
        }

        .row:before{
            background-color: #4f87fb;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            content: "";
            display: block;
            z-index: 0;
            opacity: .8;
        }

        .stats-item {
            display: flex;
            justify-content: center;
            :first-child {
                border-radius: 10px 0 0 10px;
                @media (min-width: 768px) { 
                    &:after {
                        content: "";
                        height: 100%;
                        width: 1px;
                        background-color: hsla(0,0%,100%,.22);
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .stats-item {
                padding: 30px 25px;
            }
        }
       .counter {
            line-height: 46px;
            letter-spacing: -.5px;
            text-align: left;
            color: #fff;
            font-weight: 900;
            font-size: 1.6rem
       }
       .mbr-card-title {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 1px;
            text-align: center;
            color: #fff;
            display: block;
            font-weight: 300;
       }

}
        

    .justify-content-center {
        justify-content: center!important;
    }





    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }




    .hero {
        max-width: 1024px;
        margin: 0 auto;

        .learn-more-container{
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            h2{
                flex:1;
                font-size:2em;
                text-align: center;
                margin-bottom:20px;
            }
            .text{
                flex:3;
                text-align:center;
                margin-bottom:40px;
            }
            .btn {
                padding: 20px 0;
                color: #fff;
                font-weight: bold;
                text-transform: uppercase;
                border-radius: 30px;
                background-color: #447fff;
                letter-spacing: 1px;
                text-align: center;
                font-size: 16px;
                margin-right: 0;
                width:70%;
            }
        }
        .location {
            font-weight: 500;
        }
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
       
        .with-logo {
            background-image: url("../../assets/img/stethoscope.jpg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
        .logo-mobile-only {
            display: none;
        }
    }
    .info {
        max-width: 1024px;
        margin: 0 auto;
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
        .btn {
            padding: 20px;
            width: 40%;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 30px;
            background-color: #447fff;
            letter-spacing: 1px;
            text-align: center;
            font-size: 16px;
            margin-right: 0;
        }
        .with-logo {
            background-image: url("../../assets/img/heartbeat.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
    }
    .steps {
        // max-width: 1024px;
        background-color: #77a2ff;
        color: #eee;
        text-align: center;
        text-align: -webkit-center;
        margin: 0 auto;
        .lead {
            width: 100%;
        }
        .hero-header {
            font-weight: 800;
            font-size: 56px;
            max-width: 600px;
        }
        .btn {
            padding: 20px;
            width: 40%;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 30px;
            background-color: #447fff;
            letter-spacing: 1px;
            text-align: center;
            font-size: 16px;
            margin-right: 0;
        }
        .with-logo {
            background-image: url("../../assets/img/heartbeat.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
    }
    &__offer {
        .img-wrapper {
            height: 64px;
            margin-bottom: 32px;
            img {
                height: 100%;
            }
        }
    }
    &--office {
        background-color: $white;
    }
    @media only screen and (max-width: 465px) {
        .stats-info {
            padding: 13px 50px 13px 50px;
        }
        .hero {
            .btn {
                width: 60%;            
            }

            .row {
                 display: block;
                // flex-direction: column;
                // align-items: center;
            }

            .learn-more-container {
                text-align: center;
                margin-bottom:30px;
                h2{
                    font-size:20px;
                    font-weight:bold;
                    text-align: center;

                }
                .lead{
                    font-size:14px;
                    text-align: center;
                }
                .btn {
                    width: 30%;
                    height:50%;
                    font-size:14px; 
                    padding:5px 0; 
                    display: inline-block;            
                }
            }
            
            
            .logo-mobile-only {
                position: absolute;
                top: 155px;
                right: 35px;
                display: block;
            }
            .with-logo {
                background-image: none !important;
            }
            .hero-header {
                word-spacing: 9999999px;
            }
        }
    }
    @media only screen and (max-width: 321px) {
        .hero {
            .row {
                 display: block;
                // flex-direction: column;
               
            }

            .learn-more-container {
                text-align: center;
                margin-bottom:30px;
                h2{
                    font-size:20px;
                    font-weight:bold;
                    text-align: center;

                }
                .lead{
                    font-size:14px;
                    text-align: center;
                }
                .btn {
                    width: 30%;
                    height:50%;
                    font-size:14px; 
                    padding:5px 0;   
                    display: inline-block;         
                }
            }
           

           
            .logo-mobile-only {
                position: absolute;
                top: 170px;
                right: -60px;
                display: block;
            }
        }
    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .hero {
            // padding-left: 45px;
        }
    }
}

.learn-more-dialog {
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.6);

    .MuiDialog-paperWidthSm-9 {
        max-height: unset;
    }
    .MuiDialog-container-4 {
        height: unset;
    }
    .MuiDialog-paper-5 {
        margin-top: 28px;
    }
    .jss6 {
        max-height: unset;
    }
    .jss4 {
        height: unset;
    }
    .jss5 {
        margin-top: 28px;
    }
}

.alert-danger, .alert-warning {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}