.problem-page {
    table {    
        .row {
            background-color: rgba(242,245,245,0.8);
            box-shadow: inset 0 -1px 0 0 rgba(100,121,143,0.122);
        }

        .message {
            width: 55%;
            font-size: 14px;
        }

        .screenshot {
            width: 15%;
            text-align: center;

            img {
                width: 100px !important;
            }
        }

        .person {
            width: 15%;
            text-align: center;
        }

        .created-at {
            width: 15%;
            color: gray;
            font-size: 12px;
            text-align: center;
        }
    }
}