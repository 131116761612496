.main-banner {
    display: flex;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-color: #eee;
    
    .row {
        text-align: center;
    }
    .mbr-overlay {
        min-height: 100vh;
        bottom: 0;
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
    .mbr-section-title {
        font-weight: 900;
        font-size: 3.4375rem;
        .accent-word {
            color: #4f87fb;
        }
    }
    @media (max-width: 768px) {
        .mbr-section-title {
            font-size: calc(1.85313rem + 1.58438*(100vw - 20rem)/28);
        }
    }
    .mbr-section-subtitle {
        color: #4f87fb;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 2px;
        font-size: .9rem;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 47px;

        &__learn-more {
            line-height: 26px;
            letter-spacing: 1px;
            font-weight: 700;
            font-size: 13px;
            border-radius: 4px;
            margin: .4rem .6rem .4rem 0!important;
            padding: 15px 40px;
            align-items: center;
            display: inline-flex;
            background-color: #4f87fb!important;
            border-color: #4f87fb!important;
            color: #fff!important;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,.18);
            transition: all .3s ease-in-out;
            &:hover {
                box-shadow: none!important;
                background-color: #130947!important;
                border-color: #130947!important;
                color: #fff!important;
            }
        }
        &__heart {
            span {
                line-height: 1;
                display: inline-block;
            }
        }
        &__get-refill {
            font-size: .9rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #130947;
            font-weight: 700;
            cursor: pointer;
        }
    }
}

@media (max-width: 768px) {
    .main-banner {
        &__buttons {
            display:flex;
            flex-direction: column;
            align-items: center; 
            margin-top: 57px; 
        }
    }
}