.note-revision-dialog{
    .note-revision {
        background-color: $light-gray;
        border-radius: 10px;
        margin: 10px 0px;
        border: 1px solid $medium-gray;

        .text {
            padding: 5px 10px;
        }

        .footer {
            background-color: $footer-purple;
            border-radius: 0px 0px 10px 10px;
            padding: 5px 10px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}