.profile-page {
    .confirm-error-div {
        display: flex;
        align-items: flex-end;

        label {
            color: red;
        }
    }

    .privatepay-text {
        font-weight: 700;
        font-size: 15px;
        color: #606060;
    }
}
