#message {
    padding: 10px;

    .subject {
        margin-bottom: 20px;
    }
    
    .title {
        display: flex;
        justify-content: space-between;
    }
    
    .person {
        font-weight: bold;
    }
    
    .date {
        color: rgb(95, 99, 104);
        font-size: 12px;
    }
    
    .content {
        width: 80%;
    }
    
    .replies {
        padding-left: 10px;
        padding-bottom: 20px;
    }
}

