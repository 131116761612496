#manage-data-page{
    .title {
        margin-bottom: 10px;
        color: $title-gray;
        font-weight: 500;
        font-size: 16px;
    }

    .custom-select {
        width: auto;            
    }

    .import-data, .display-data {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        select, input {
            margin-right: 20px;
        }
    }

    .display-data {
        align-items: flex-start;

        .display-json {
            margin: 0px 20px;
    
            header {
                font-size: 16px;
                font-weight: 500;
                margin: 5px 0px;
            }
    
            .json {
                background-color: $light-gray;
                padding: 10px;
                font-size: 13px;
            }
        }
    } 
}