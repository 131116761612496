.faq-container{
    height:100%;
    margin:0 100px;

    h1{
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        background:#85BEF6;
        text-align: center;
        color:white;
        min-height: 100px;
        margin-bottom:20px;
    }
    .content-container{
        .item{
            .title{
                font-size:16px;
                background: #FBF8F9;
                padding:8px 0;
                font-weight: bold;
                .question{
                    color:red;
                    margin-right:5px;
                }
            }
            .text{
                font-size:16px;
                .answer{
                    color:#4788F4;
                    margin-right:5px;
                }
                
            }
        }
    }
    @media only screen and (max-width: 465px) {
        margin:0 2px;

    }
    @media only screen and (max-width: 321px) {
        margin:0 2px;

    }
    /* Tablets (landscape) */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    }
}

.faq {
    padding-top: 90px;
    padding-bottom: 75px;
    position: relative;
    background-color: #fff;
    &__title {
        div {
            margin-bottom: 70px;
        }
        &__main {
            font-size: 13px;
            line-height: 24px;
            letter-spacing: 2px;
            color: #4f87fb;
            font-weight: 800;
        }
        &__sub {
            line-height: 48px;
            letter-spacing: -.5px;
            color: #130947;
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: .5rem;

            span {
                font-weight: 900;
            }
        }
    }
    &__text {
        font-size: 1rem;
        &__item {
            border: none;
            border-bottom: 2px solid #efefef!important;
            .card-header {
                background-color: initial;
                border: 0;
                padding: 0;
                a {
                    color: #000!important;
                    margin-bottom: 0;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding-right: 3rem!important;
                    h4 {
                        padding: 1.2rem 0;
                        margin-bottom: 0;
                        font-weight: 400;
                        line-height: 1.5;
                        letter-spacing: -.5px;
                        color: #28303f;
                        font-size: 1.125rem;
                    }
                    text-decoration: none;
                }
            }
            .toggle-icon {
                line-height: 1.6!important;
                right: 7px;
                position: absolute;
                top: 36px;
                transform: none;
                display: inline-block;
                height: 2px;
                margin-left: 0;
                width: 12px;
                transition: all .3s;
                padding: 0!important;
                font-size: 1.4rem!important;
                color: #4f87fb;
                &::before {
                    height: 12px;
                    margin-left: -1px!important;
                    width: 2px;
                    margin-top: -6px;
                    background-color: currentColor;
                    border: 0;

                    background: currentColor;
                    box-sizing: border-box;
                    content: "";
                    display: block;
                    left: 50%;
                    position: absolute;
                    top: 50%;

                    transform: scaleY(1)!important;
                    transition: all .3s;
                }
                &::after {
                    height: 2px;
                    width: 12px;
                    margin-left: -6px;
                    margin-top: -1px;

                    background: currentColor;
                    border: 0;
                    box-sizing: border-box;
                    content: "";
                    display: block;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                }
            }
            .panel-body {
                padding: 14px 20px;
                .panel-text {
                    font-size: 16px;
                    color: #6d7a8c;
                    line-height: 1.5;
                }
            }
        }
    }
}