#alert{
    .alert {
        padding: 15px;
        top: 78px;
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: 100%;
    
        .title {
            font-size: 17px;
            font-weight: 500;
        }
    
        .messages {
            font-size: 13px;
        }
    }
    
    .alert-shown {
        opacity: 1;
        z-index: 1300;
        transition: all 250ms linear;
      }
      
    .alert-hidden {
        opacity: 0;
        z-index: -1;
        transition: all 500ms linear;
    }
}